import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import { ECharts } from './plugins/vue-echarts'
import i18n from './i18n'

import VueSocketIO from "vue-socket.io";

const socketioUrl = process.env.VUE_APP_SOCK_URL ?? 'http://127.0.0.1:8892';

console.log("socketioUrl:", socketioUrl);

const socketDebug = process.env.VUE_APP_SOCK_DEBUG === '1' ?? false;

const secure = process.env.NODE_ENV === 'production';

if (process.env.NODE_ENV !== 'production') {
    window.store = store;
}

Vue.component('v-chart', ECharts)

Vue.use(new VueSocketIO({
    debug: socketDebug,
    connection: socketioUrl,
    options: {
        path: '/socket.io',
        autoConnect: true,                //启动自从自动连接
        secure: secure,
        transports: ['websocket', 'polling'],        // ['websocket', 'polling']
        reconnection: true,               //启动重新连接
        reconnectionAttempts: 5,          //最大重试连接次数
        reconnectionDelay: 2000,          //最初尝试新的重新连接等待时间
        reconnectionDelayMax: 10000,      //最大等待重新连接,之前的2倍增长
        timeout: 20000
    },
    vuex: {
        store,
        actionPrefix: 'Socket',
        mutationPrefix: 'Socket'
    },

}))

Vue.prototype.$EventBus = new Vue()
Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app');
