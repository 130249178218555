import request from './axios-auth'
import { checkNull, checkObject, } from '@/utils'

export async function get_ccs() {
    const response = await request({ method: 'get', url: "/api/strategy/ccs", })

    if (response.data.code === 0) {
        return response.data.data.ccs
    }

    return []
}

export async function get_subscriptions() {
    const response = await request({ method: 'get', url: "/api/strategy/subscriptions", })

    if (response.data.code === 0) {
        return response.data.data.subscriptions
    }

    return []
}

export async function get_strategy_status(strategyName, instanceId) {
    const response = await request({ method: 'get', url: "/api/strategy/get_status", params: { strategyName, instanceId, } })

    if (response.data.code === 0) {
        return response.data.data.strategy_status
    }

    return 'UNKNOWN_from_client'
}

export async function get_strategy_param(strategyName, instanceId) {
    const response = await request({ method: 'get', url: "/api/strategy/get_param", params: { strategyName, instanceId, } })

    if (response.data.code === 0) {
        return response.data.data.strategy_param
    }

    return {}
}

export async function get_grids(strategyName, instanceId) {
    const response = await request({ method: 'get', url: "/api/strategy/get_grids", params: { strategyName, instanceId, } })

    if (response.data.code === 0) {

        const tasks = response.data.data.strategy_tasks

        const activeTasks = checkObject(tasks) && !checkNull(tasks['tasks']) ? Object.keys(tasks['tasks']).map((key) => {
            const currTask = tasks['tasks'][key]
            currTask.params = JSON.stringify(currTask.params)
            return currTask
        }) : []

        const oldTasks = checkObject(tasks) && !checkNull(tasks['oldTasks']) !== undefined ? tasks['oldTasks'].map((currTask) => {
            currTask.params = JSON.stringify(currTask.params)
            return currTask
        }) : []

        return {
            tasks,
            activeTasks,
            oldTasks,
        }
    }

    return {
        tasks: [],
        activeTasks: [],
        oldTasks: [],
    }
}

export async function create_grid(params) {

    const response = await request({ method: 'post', url: "/api/strategy/create_grid", data: params, })

    if (response.data.code === 0) {
        console.log('create_grid:', response.data.data)
        return response.data.data.grid_params
    }

    return undefined
}

export async function submit_strategy_task(params) {

    const response = await request({ method: 'post', url: "/api/strategy/new_task", data: params, })

    if (response.data.code === 0) {
        console.log('submit_strategy_task:', response.data.data)
        return response.data.data.task_submit
    }

    return undefined
}

export async function cancel_strategy_task(params) {

    const response = await request({ method: 'post', url: "/api/strategy/cancel_task", data: params, })

    if (response.data.code === 0) {
        console.log('cancel_task:', response.data.data)
        return response.data.data.task_cancel
    }

    return undefined
}

export async function get_strategy_tasks(strategyName, instanceId) {
    const response = await request({ method: 'get', url: "/api/strategy/get_tasks", params: { strategyName, instanceId, } })

    if (response.data.code === 0) {

        const tasks = response.data.data.strategy_tasks

        const activeTasks = checkObject(tasks) && !checkNull(tasks['tasks']) ? Object.keys(tasks['tasks']).map((key) => {
            const currTask = tasks['tasks'][key]
            currTask.params = JSON.stringify(currTask.params)
            return currTask
        }) : []

        const oldTasks = checkObject(tasks) && !checkNull(tasks['oldTasks']) !== undefined ? tasks['oldTasks'].map((currTask) => {
            currTask.params = JSON.stringify(currTask.params)
            return currTask
        }) : []

        return {
            tasks,
            activeTasks,
            oldTasks,
        }
    }

    return {
        tasks: [],
        activeTasks: [],
        oldTasks: [],
    }
}

export async function update_strategy_param({ strategyName, instanceId, symbols, params, updateType, }) {
    const data = { strategyName, instanceId, symbols, params, updateType, }

    const response = await request({ method: 'post', url: "/api/strategy/update_param", data, })

    if (response.data.code === 0) {
        return response.data.data.strategy_updated_param
    }

    return {}
}

export async function create_strategy({ strategyName, instanceId, gateways, isOnTimer, accountType }) {
    const data = { strategyName, instanceId, gateways, }
    
    if (isOnTimer !== undefined) {
        data.isOnTimer = isOnTimer
    }
    if (accountType !== undefined) {
        data.isOnTimer = accountType
    }

    const response = await request({ method: 'post', url: "/api/strategy/new", data, })

    try {
        if (response.data.code === 0) {
            return response.data.data.strategy
        }
    } catch (reason) {
        debugger
    }

    return undefined
}

export async function get_strategy_list() {
    const response = await request({ method: 'get', url: "/api/strategy/list", })

    if (response.data.code === 0) {
        const lst = response.data.data.strategy_list
        return lst.map((item) => {
            item.symbols = item.symbols ? item.symbols.map((item2) => {
                return item2.symbol
            }) : [];
            return item
        })
    }

    return []
}

export async function get_strategy_orders(strategyName, instanceId) {
    const response = await request({ method: 'get', url: "/api/strategy/orders", params: { strategyName, instanceId, } })

    if (response.data.code === 0) {
        let strategy_orders = response.data.data.strategy_orders;
        if (strategy_orders !== undefined && strategy_orders !== null) {
            const orders = []
            
            Object.keys(strategy_orders).forEach((item) => {
                let order = strategy_orders[item].order
                if (order !== undefined && order !== null) {
                    order.memo = strategy_orders[item].orderInput ? strategy_orders[item].orderInput.memo : '';
                    order.source = strategy_orders[item].orderInput ? strategy_orders[item].orderInput.source : '';
                    order.duration = strategy_orders[item].orderInput ? strategy_orders[item].orderInput.duration : '';
                    orders.push(order)
                } else {
                    // console.log('orderInput:', strategy_orders[item].orderInput)
                }
            })

            const open_orders = orders.filter((item) => {
                return item != null && item.hasOwnProperty("status") && (item.status == 'NEW' || item.status == 'PARTIALLY_FILLED'
                 || item.status == 'PENDING_CANCEL' || item.status == 'New' || item.status == 'PartiallyFilled' || item.status == 'PendingCancel')
            });
            const historical_orders = orders.filter((item) => {
                return item != null && item.hasOwnProperty("status") && (item.status == 'CANCELED' || item.status == 'FILLED' 
                || item.status == 'EXPIRED' || item.status == 'REJECTED'|| item.status == 'Cancelled' || item.status == 'Filled'
                || item.status == 'Cancelled')
            });
            return {
                open_orders,
                historical_orders,
            }
        }
    }

    return { open_orders: [], historical_orders: [] }
}

export async function get_strategy_positions(strategyName, instanceId) {
    const response = await request({ method: 'get', url: "/api/strategy/positions", params: { strategyName, instanceId, } })

    if (response.data.code === 0) {
        const strategy_position = response.data.data.strategy_position
        if (strategy_position !== undefined && strategy_position !== null) {
            const strategy_positions = response.data.data.strategy_position.positions
            const strategy_pnl = response.data.data.strategy_position.pnlTotal
            const strategy_maxPnl = response.data.data.strategy_position.maxPnlTotal
            const strategy_margin = response.data.data.strategy_position.marginTotal
            const ratio = response.data.data.strategy_position.pnlRatio

            if (strategy_positions !== undefined && strategy_positions !== null) {
                return {
                    positions: Object.keys(strategy_positions).sort().map((key) => strategy_positions[key]),
                    pnlTotal: strategy_pnl,
                    maxPnlTotal: strategy_maxPnl,
                    marginTotal: strategy_margin,
                    pnlRatio: ratio,
                }
            }
        }
    }

    return {
        positions: [],
        pnlTotal: 0,
        maxPnlTotal: 0,
        marginTotal: 0,
        pnlRatio: 0,
    }

}

export async function update_strategy_position({ strategyName, instanceId, gatewayName, symbol, positionSide, entryPrice, positionAmt, accountType, }) {
    const data = { strategyName, instanceId, gatewayName, symbol, positionSide, entryPrice, positionAmt, accountType, }

    const response = await request({ method: 'post', url: "/api/strategy/update_position", data, })

    if (response.data.code === 0) {
        return response.data.data.strategy_position
    }

    return {}
}

export async function delete_strategy_position({ strategyName, instanceId, symbol, positionSide, entryPrice, positionAmt, }) {
    const data = { strategyName, instanceId, symbol, positionSide, entryPrice, positionAmt, }
    const response = await request({ method: 'delete', url: "/api/strategy/delete_position", data, })

    if (response.data.code === 0) {
        return response.data.data.strategy_position
    }

    return {}
}

