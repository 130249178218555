import request from './axios-auth'

export async function get_global_market_info() {

    const response = await request.get("/api/market/global_market_info")

    if (response.data.code === 0) {
        return response.data.data.global_market_info
    }

    return []
}

export async function get_exchange_infos() {

    const response = await request.get("/api/market/exchange_infos")

    if (response.data.code === 0) {
        return response.data.data.exchange_infos
    }

    return {}
}

export async function get_candlestick({
    symbol,
    accountType,
    interval,
    startTime,
    endTime,
    limit,
}) {
    
    const params = {
        symbol,
        accountType,
        interval,
        startTime,
        endTime,
        limit,
    }

    const response = await request.get("/api/market/candlestick", { params })

    // console.log(response.data)

    if (response.data.code === 0) {
        return response.data.data.candlesticks
    }

    return []
}
