import request from './axios-auth'

import { is_futures } from '@/utils'

export async function get_account_info() {
    const response = await request({ method: 'get', url: "/api/account/info", })

    if (response.data.code === 0) {
        return {
            acc_name: response.data.data.acc_name,
            baseInfos: response.data.data.baseInfos,
        }
    }

    return {}
}

function genbalances(rawBalances) {
    const balances = [];
    Object.keys(rawBalances).forEach((key) => {
        const data = rawBalances[key];
        data["symbol"] = key;
        balances.push(data);
    });
    return balances;
}

function genpositions(rawPositions) {
    const positions = Object.keys(rawPositions).filter((item) => rawPositions[item].positionAmt !== 0).map((key) => rawPositions[key]);
    return positions;
}

export async function get_account_list() {
    const response = await request({ method: 'get', url: "/api/account/list", })

    let accLst = [];
    let accounts = {};

    let gateways = [];

    if (response.data.code === 0) {
        accounts = response.data.data.accounts
        if (accounts !== null && accounts !== undefined) {
            Object.keys(accounts).forEach((key) => {

                const accItem = {}
                accItem.name = key
                gateways.push(key)
                const value = accounts[key];
                accItem.type = value.accountType;

                accItem.balances = genbalances(value.balances);

                if (is_futures(accItem.type)) {
                    accItem.total_positions = value.positions;
                    accItem.positions = genpositions(value.positions);
                    accItem.dual_map = value.dualMap;
                }

                accLst.push(accItem);

            });
        }

    }

    return {
        accLst,
        gateways,
        accounts,
    }
}
