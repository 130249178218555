<template>
  <router-view/>
</template>

<script>

export default {
  name: "App",

  created() {
  },
  mounted: function () {
  }

};
</script>
