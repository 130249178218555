import { removeToken, } from "@/utils";

export const routerMixins = {
  methods: {
    jump(href) {
      if (href === this.$route.fullPath) {
        return;
      }
      this.$router.push(href);
    },
    logout: function () {
      removeToken();
      this.$router.push('/login');
    }
  }
}
