import request from './axios-auth'

export async function login(username, password) {
    const response = await request.post("/api/user/login", { username, password, })

    if (response?.data) {
        localStorage.setItem('x_token', response.data.data.token)
    }
}

export async function verify() {
    return await request.post('/api/user/verify')
}
