export function removeToken() {
    localStorage.removeItem('x_token');
}

export function get_sorted_value_list(obj) {
    if (obj === null || obj === undefined) {
        return []
    }
    return Object.keys(obj).sort().map((key) => obj[key])
}

export const containsAll = (obj, arr) => {
    if (arr.length === 0) {
        return true
    }
    for (const str of arr) {
        if (Object.keys(obj).includes(str)) {
            continue;
        } else {
            return false;
        }
    }
    return true;
};

export function roundN(num, n) {
    return parseFloat(parseFloat(Math.round(num * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n));
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export function checkNull(item) {
    return item === null || item === undefined
}

export function checkObject(item) {
    return !checkNull(item) && typeof(item) === 'object'
}
