import axios from 'axios'

const baseURL = process.env.VUE_APP_BACKEND_BASE_URL ?? 'http://127.0.0.1:8891'

const instance = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "X-Token": {
            toString() {
                const x_token = localStorage.getItem('x_token')
                const isEmpty = (x_token === null || x_token === undefined || x_token.trim() === '')
                return  isEmpty ? '' : x_token
            }
        },
    },
});

export default instance
