import request from './axios-auth'
import { get_sorted_value_list, } from '@/utils'

export async function change_leverage({
    gatewayName,
    symbol,
    leverage,
}) {
    if (gatewayName == undefined || symbol === undefined) {
        console.error('change_leverage check failed.')
        return undefined
    }

    if (leverage === undefined) {
        leverage = 1
    }

    const data = {
        gatewayName,
        symbol,
        leverage,
    }

    const response = await request({ method: 'post', url: "/api/trade/change_leverage", data, })

    return response
}

export async function get_open_orders() {

    const response = await request({ method: 'get', url: "/api/trade/open_orders", })

    if (response.data.code === 0) {
        const rawOpenOrders = response.data.data.open_orders

        const openOrderMap = {}

        Object.keys(rawOpenOrders).forEach((item) => {
            const order = rawOpenOrders[item]
            if (order !== undefined) {
                const key = order.gatewayName + '_' + order.accountType
                let orderlist = []
                if (openOrderMap.hasOwnProperty(key)) {
                    orderlist = openOrderMap[key];
                }
                orderlist.push(order)
                openOrderMap[key] = orderlist;
            }
        })

        return {
            rawOpenOrders,
            openOrderMap,
        }
    }

    return null;
}

export async function get_all_orders({ gatewayName, symbol, limit }) {

    const params = {
        gatewayName,
        symbol,
        limit,
    }

    const response = await request({ method: 'get', url: "/api/trade/all_orders", params, })

    if (response.data.code === 0) {
        const all_orders = response.data.data.all_orders

        return {
            all_orders,
        }
    }

    return null;
}

export async function get_user_trades({ gatewayName, symbol, orderId, startTime, endTime, fromId, limit }) {

    const params = {
        gatewayName,
        symbol,
        orderId,
        startTime,
        endTime,
        fromId,
        limit,
    }

    const response = await request({ method: 'get', url: "/api/trade/user_trades", params, })

    if (response.data.code === 0) {
        const user_trades = response.data.data.user_trades

        return {
            user_trades,
        }
    }

    return null;
}

function formatOrder({
    gatewayName,
    symbol,
    strategyName,
    instanceId,
    accountType,
    orderSide,
    positionSide,
    orderType,
    timeInForce,
    qty,
    price,
    workingType,
    orderRespType,
    stopPrice,
    trailingDelta,
    reduceOnly,
}) {

    if (symbol === undefined || accountType === undefined || orderSide === undefined
        || ((accountType == 'BINANCE_USDT_FUTURE' || accountType == 'BINANCE_USDT_FUTURE_TEST') && positionSide === undefined)
        || gatewayName == undefined || qty === undefined) {
        console.error('place order check failed.')
        debugger
        return undefined
    }

    if (strategyName == undefined) {
        strategyName = ''
    }

    if (instanceId == undefined) {
        instanceId = ''
    }

    if (orderType === undefined) {
        orderType = 'LIMIT'
    }

    if (orderRespType === undefined) {
        orderRespType = 'RESULT'
    }

    if (typeof (qty) === 'number') {
        qty = Math.abs(qty)
    } else if (typeof (qty) === 'str') {
        qty.replace("-", "")
    }

    // try {
    //     qty = Math.abs(parseFloat(qty))
    // } catch(reason) {
    //     console.error(reason)
    // }

    const orderObj = {
        gatewayName,
        symbol,
        strategyName,
        instanceId,
        accountType,
        orderSide,
        orderType,
        qty,
        price,
        orderRespType,
    }

    if (positionSide != undefined) {
        orderObj.positionSide = positionSide;
    }

    if (workingType != undefined) {
        orderObj.workingType = workingType;
    }

    if (timeInForce != undefined) {
        orderObj.timeInForce = timeInForce;
    }

    if (stopPrice != undefined && stopPrice !== null && stopPrice.trim() !== '') {
        orderObj.stopPrice = stopPrice;
    }

    if (trailingDelta != undefined) {
        orderObj.trailingDelta = trailingDelta;
    }

    if (reduceOnly !== undefined) {
        orderObj.reduceOnly = reduceOnly;
    }

    console.log('formatOrder:', orderObj)

    return orderObj

}

export async function place_order(order) {

    const data = formatOrder(order);

    const response = await request({ method: 'post', url: "/api/trade/place_order", data, })

    return response
}

export async function batch_place_order(orders, strategyName = undefined, instanceId = undefined) {

    if (strategyName === undefined) {
        strategyName = ''
    }

    if (instanceId === undefined) {
        instanceId = ''
    }

    const orderInputs = orders.map((item) => formatOrder(item))

    const data = {
        orderInputs,
    }

    const response = await request({ method: 'post', url: "/api/trade/batch_place_order", data, })

    return response
}

export async function cancel_order({
    gatewayName,
    symbol,
    accountType,
    orderId,
    clientOrderId,
}) {
    if (gatewayName === undefined || symbol === undefined || accountType === undefined || (orderId === undefined && clientOrderId === undefined)) {
        console.error('cancel order check failed.')
        return undefined
    }

    const data = {
        gatewayName,
        symbol,
        accountType,
        orderId,
        clientOrderId,
    }

    console.log('cancel:', data)

    const response = await request({ method: 'post', url: "/api/trade/cancel_order", data, })

    return response

}
