import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/views/dashboard/Login'

import {verify} from "@/api";
import {removeToken,} from "@/utils";

const SKIP_AUTH = process.env.VUE_APP_SKIP_AUTH === '1'

Vue.use(Router)

const routes = [
    {
        path: '/login',
        component: Login,
        meta: {requiresAuth: false,},
    },
    {
        path: '/',
        component: () => import('@/views/dashboard/Index'),
        meta: {requiresAuth: !SKIP_AUTH,},
        children: [
            // Dashboard
            {
                name: 'Dashboard',
                path: '',
                component: () => import('@/views/dashboard/Dashboard'),
            },
            // Pages
            {
                name: 'Market',
                path: 'pages/market',
                component: () => import('@/views/dashboard/pages/Market'),
            },
            {
                name: 'TradingPanel',
                path: 'pages/market/:symbol',
                component: () => import('@/views/dashboard/pages/TradingPanel'),
            },
            {
                name: 'Account',
                path: 'pages/account',
                component: () => import('@/views/dashboard/pages/Account'),
            },
            {
                name: 'Strategies',
                path: 'pages/strategies',
                component: () => import('@/views/dashboard/pages/strategies/StrategyList'),
            },
            {
                name: 'DualPerpetualStrategy',
                path: 'pages/strategies/details/DualPerpetualStrategy',
                component: () => import('@/views/dashboard/pages/strategies/DualPerpetualStrategy/index.vue'),
            },
            {
                name: 'NewDualPerpetualStrategy',
                path: 'pages/strategies/details/NewDualPerpetualStrategy',
                component: () => import('@/views/dashboard/pages/strategies/DualPerpetualStrategy/index.vue'),
            },
            {
                name: 'SpotAlgoTradingStrategy',
                path: 'pages/strategies/details/SpotAlgoTradingStrategy',
                component: () => import('@/views/dashboard/pages/strategies/SpotAlgoTradingStrategy/index.vue'),
            },
            {
                name: 'FirstBloodStrategy',
                path: 'pages/strategies/details/FirstBloodStrategy',
                component: () => import('@/views/dashboard/pages/strategies/FirstBloodStrategy/index.vue'),
            },
            {
                name: 'PerpetualMMStrategy',
                path: 'pages/strategies/details/PerpetualMMStrategy',
                component: () => import('@/views/dashboard/pages/strategies/PerpetualMMStrategy/index.vue'),
            },
            {
                name: 'GridTradingStrategy',
                path: 'pages/strategies/details/GridTradingStrategy',
                component: () => import('@/views/dashboard/pages/strategies/GridTradingStrategy/index.vue'),
            },
            {
                name: 'System',
                path: 'pages/system',
                component: () => import('@/views/dashboard/pages/System'),
            },
            {
                name: 'Database',
                path: 'pages/database',
                component: () => import('@/views/dashboard/pages/Database'),
            },
            {
                name: 'User Profile',
                path: 'pages/user',
                component: () => import('@/views/dashboard/pages/UserProfile'),
            },
            {
                name: 'Notifications',
                path: 'components/notifications',
                component: () => import('@/views/dashboard/component/Notifications'),
            },
            {
                name: 'Icons',
                path: 'components/icons',
                component: () => import('@/views/dashboard/component/Icons'),
            },
            {
                name: 'Typography',
                path: 'components/typography',
                component: () => import('@/views/dashboard/component/Typography'),
            },
            // Upgrade
            {
                name: 'Upgrade',
                path: 'upgrade',
                component: () => import('@/views/dashboard/Upgrade'),
            },
        ],
    },
]

//
const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "active",
    scrollBehavior: (to) => {
        if (to.hash) {
            return {selector: to.hash}
        } else {
            return {x: 0, y: 0}
        }
    }
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('x_token');
    const requireAuth = to.matched.some(record => record.meta.requiresAuth);

    // console.log('before:', requireAuth, to.path, token)

    if (to.path === '/login') {
        if (token) {
            verify().then(() => {
                // console.log('verified 1!')
                next('/');
            }).catch((reason) => {
                // console.log('reason:', reason)
                removeToken();
                next();
            });
        } else {
            next()
        }
    } else if (requireAuth) {
        if (token) {
            verify().then((res) => {
                // console.log('verified 2!')
                next();
            }).catch((reason2) => {
                // console.log('reason2:', reason2)
                removeToken();
                next('/login');
            })
        } else {
            next('/login');
        }
    } else {
        next();
    }

});

export default router;
