import { get_exchange_infos, } from "@/api";

export const updateExchangeInfoMixin = {

  methods: {
    async fetchExchangeInfo() {
      
      const total_infos = await get_exchange_infos()

      const gen_infos = (exchange_info) => {
      
        const exchangeInfo = {};
        let filterLst = [];
        if (exchange_info != null && exchange_info.symbols !== null && exchange_info.symbols !== undefined) {
          filterLst = Object.keys(exchange_info.symbols).sort();
          filterLst.forEach((key) => {
            const item = exchange_info.symbols[key]
            exchangeInfo[key] = item
          });
  
        }

        return { filterLst, exchangeInfo };

      }
      
      const new_total_infos = {}
      
      Object.keys(total_infos).forEach((item) => {
        new_total_infos[item] = gen_infos(total_infos[item])
      })
      
      return {
        total_infos,
        new_total_infos,
      }

    },

  }

}
