import { place_order, batch_place_order, cancel_order, } from "@/api";

export const tradeMixin = {

  methods: {
    async cancelOrder({
      gatewayName,
      strategyName,
      instanceId,
      symbol,
      accountType,
      orderId,
      clientOrderId,
    }) {
      return await cancel_order({
        gatewayName,
        strategyName,
        instanceId,
        symbol,
        accountType,
        orderId,
        clientOrderId,
      })
    },

    async coverPosition(item, gatewayName, price = undefined, orderType = 'MARKET') {

      if (gatewayName == undefined) {
        console.error("no gatewayName defined!");
        return;
      }

      const strategyName = item.strategyName === undefined ? '' : item.strategyName
      const instanceId = item.instanceId === undefined ? '' : item.instanceId
      const orderSide = item.positionSide === 'LONG' ? 'SELL' : 'BUY'

      const params = {
        symbol: item.symbol.split('|')[0],
        accountType: item.accountType,
        gatewayName,
        strategyName,
        instanceId,
        orderSide,
        orderType,
        qty: item.positionAmt,
      };

      if (price !== undefined) {
        params.price = price
      }

      if (item.accountType === 'BINANCE_USDT_FUTURE' || item.accountType === 'BINANCE_USDT_FUTURE_TEST') {
        params.positionSide = item.positionSide;
      } else if (item.accountType === 'BYBIT_USDT_FUTURE' || item.accountType === 'BYBIT_USDT_FUTURE_TEST') {
        params.reduceOnly = true;
      }

      if (orderType == 'LIMIT') {
        params.timeInForce = 'GTC'
      }
      
      console.log('coverPosition:', params)

      return await place_order(params)

    },

  }

}
