import request from './axios-auth'

const fmt = 'yyyy-MM-dd HH:mm:ss.SSS'

export async function get_system_time(type) {
    const response = await request({method: 'get', url: "/api/system/time", params: { type, }, })

    let system_time = {}

    let serverTime = ''

    let localTime = ''

    if (response.data.code === 0) {
        system_time = response.data.data.system_time
        if (system_time != null && system_time != undefined) {
            serverTime = new Date(system_time.serverTime)
            serverTime = `${serverTime.toLocaleString()} ${serverTime.getMilliseconds()}`
            localTime = new Date(system_time.localTime)
            localTime = `${localTime.toLocaleString()} ${localTime.getMilliseconds()}`
        }
    }

    return {
        system_time,
        serverTime,
        localTime,
    }
}

export async function get_system_info() {
    const response = await request({method: 'get', url: "/api/system/info", })

    let queue_size = undefined

    if (response.data.code === 0) {
        queue_size = response.data.data.queue_size
    }

    return {
        queue_size,
    }
}

export async function stop_system() {
    const response = await request({method: 'post', url: "/api/system/stop", })
    return response
}

export async function restart_system() {
    const response = await request({method: 'post', url: "/api/system/restart", })
    return response
}
