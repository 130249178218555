import { DEPTH_SIZE } from '@/constants'

function fillArr(arr) {
    if (arr.length < DEPTH_SIZE) {
        let c = DEPTH_SIZE - arr.length;
        while (c > 0) {
            arr.push({});
            c--;
        }
    }

    return arr;
}

export function parse_depth(depth_event) {
    const ev = JSON.parse(depth_event)

    const symbol = ev.symbol;
    const accountType = ev.accountType;

    const asks = fillArr(ev.asks);
    asks.reverse();
    const bids = fillArr(ev.bids);

    const ask_key = `${symbol}_${accountType}_asks`;
    const bid_key = `${symbol}_${accountType}_bids`;

    return {
        symbol,
        accountType,
        asks,
        ask_key,
        bids,
        bid_key,
    }
}

export function parse_symbolticker(symbol_ticker_event) {
    const ev = JSON.parse(symbol_ticker_event)
    const symbol = ev.symbol;
    const accountType = ev.accountType;

    const key = `${symbol}_${accountType}`;

    return {
        symbol,
        accountType,
        ev,
        key,
    }
}
