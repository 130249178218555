<template>
  <v-chart class="echarts" :ref="options" :option="options" :init-options="initOptions" />
</template>

<script>
export default {
  name: "MyChart",
  props: {
    options: Object,
  },
  watch: {},
  data() {
    return {
      initOptions: {
        renderer: "canvas",
      },
    };
  },
};
</script>

<style lang="scss">
.echarts {
  width: 100%;
  height: 500px;
}

</style>
