<template>
  <v-snackbar :value="internalSnacker" :timeout="timeout" :multi-line="multiLine">
    <b :style="'color:' + color + ';'">{{ text }}</b>
  </v-snackbar>
</template>
<script>
export default {
  name: "LcToast",

  props: {
    multiLine: {
      type: Boolean,
      default: false,
    },
    snackbar: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 3000,
    },
    color: {
      type: String,
      default: 'white',
    },
    text: {
      type: String,
      default: "",
    },
  },

  computed: {
    internalSnacker: {
      get() {
        return this.snackbar;
      },
      set(newVal) {
        this.internalSnacker = newVal;
      },
    },
  },

  data() {
    return {};
  },
};
</script>

<style scoped lang="sass">
.v-snackbar--material
    margin-top: 32px
    margin-bottom: 32px

    .v-alert--material,
    .v-snack__wrapper
        border-radius: 4px

    .v-snack__content
        overflow: visible
        padding: 0
</style>
