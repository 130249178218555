export const INITIAL_MARGIN = 10;

export const INITIAL_LEVERAGE = 1;

export const INITIAL_LONG_PERCENTAGE = 0.5;

export const INITIAL_ORDER_TYPE = 'MARKET';

export const OVERLAY_TIMEOUT = 5000;

export const DEPTH_SIZE = 20;

export const EventType = {
    LeverageChange: 'LeverageChange',
}

export const Strategies = ((process.env.NODE_ENV === 'production' && process.env.VUE_APP_PRO === 'pro')
    || (process.env.NODE_ENV === 'development'))
    ? ['DualPerpetualStrategy', 'NewDualPerpetualStrategy', 'SpotAlgoTradingStrategy', 'PerpetualMMStrategy',]
    : ['DualPerpetualStrategy', 'NewDualPerpetualStrategy', 'SpotAlgoTradingStrategy',];
