function gen_chart_options({
    series,
    type,
    title,
    legend,
    height,
    start,
}) {
    if (type === undefined) {
        type = 'line'
    }

    if (start == undefined) {
        start = 0
    }

    if (title === undefined) {
        title = ''
    }

    if (series === undefined) {
        series = []
    }

    if (height === undefined) {
        height = 350
    }

    if (legend === undefined) {
        legend = series.map((item) => item.name)
    }

    return {
        title: {
            text: title,
            textStyle: {
                color: "#ffffff",
            },
        },
        legend: {
            textStyle: {
                color: "#ffffff",
            },
            data: legend,
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
        },
        tooltip: {
            trigger: "axis",
        },
        toolbox: {
            feature: {
                dataZoom: {
                    show: false,
                    // zoomOnMouseWheel: true, //Whether the wheel triggers zoom
                    // moveOnMouseMove: true, //Mouse wheel triggers scroll
                    // moveOnMouseWheel: true,
                },
            },
        },
        dataZoom: [
            {
                id: 'dataZoomX',
                type: 'slider',
                xAxisIndex: [0],
                filterMode: 'filter',
                start: start,
                end: 100,
            },
        ],
        xAxis: {
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#ffffff",
                },
            },
            type: "time",
            boundaryGap: ["0", "0"],
        },
        yAxis: [{
            name: 'Capital',
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#ffffff",
                },
            },
            type: "value",
        }, {
            name: 'Pnl',
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#ffffff",
                },
            },
            type: "value",
        }],
        series,
    }
}

export function gen_global_show_data(rawData) {

    const show_data = rawData.map((item) => {

        const symbol = item.symbol

        const longShortOIRatio = item.longShortOIRatio;
        const longShortHCRatio = item.longShortHCRatio;
        const takerLongShortStats = item.takerLongShortStats;

        const longShortOIRatioSeries = [
            {
                name: "longAccount",
                type: "line",
                data: longShortOIRatio.map((item2) => [item2.timestamp, item2.longAccount]),
            },
            {
                name: "shortAccount",
                type: "line",
                data: longShortOIRatio.map((item2) => [item2.timestamp, item2.shortAccount]),
            },
            {
                name: "longShortRatio",
                type: "line",
                yAxisIndex: 1,
                data: longShortOIRatio.map((item2) => [item2.timestamp, item2.longShortRatio]),
            },
        ];

        const longShortHCRatioSeries = [
            {
                name: "longAccount",
                type: "line",
                data: longShortHCRatio.map((item2) => [item2.timestamp, item2.longAccount]),
            },
            {
                name: "shortAccount",
                type: "line",
                data: longShortHCRatio.map((item2) => [item2.timestamp, item2.shortAccount]),
            },
            {
                name: "longShortRatio",
                type: "line",
                yAxisIndex: 1,
                data: longShortHCRatio.map((item2) => [item2.timestamp, item2.longShortRatio]),
            },
        ];

        const takerLongShortStatsSeries = [
            {
                name: "buyVol",
                type: "line",
                data: takerLongShortStats.map((item2) => [item2.timestamp, item2.buyVol]),
            },
            {
                name: "sellVol",
                type: "line",
                data: takerLongShortStats.map((item2) => [item2.timestamp, item2.sellVol]),
            },
            {
                name: "buySellRatio",
                type: "line",
                yAxisIndex: 1,
                data: takerLongShortStats.map((item2) => [item2.timestamp, item2.buySellRatio]),
            },
        ];

        const start = parseInt(100 * (longShortOIRatio.length - 30) / longShortOIRatio.length)

        const data = [{
            name: 'longShortOIRatio(大户持仓量多空比)',
            chartOptions: gen_chart_options({
                start,
                series: longShortOIRatioSeries,
            })
        }, {
            name: 'longShortHCRatio(多空持仓人数比)',
            series: longShortHCRatioSeries,
            chartOptions: gen_chart_options({
                start,
                series: longShortHCRatioSeries,
            })
        }, {
            name: 'takerLongShortStats(合约主动买卖量)',
            series: takerLongShortStatsSeries,
            chartOptions: gen_chart_options({
                start,
                series: takerLongShortStatsSeries,
            })
        }];

        return {
            symbol,
            data,
        }
    });

    return show_data
}
